<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>
      <b-col cols="auto">
        <b-form-group>
          <b-input-group size="sm">
            <b-input-group-prepend>
              <b-input-group-text class="bg-white border-right-0">
                <b-icon icon="search"></b-icon>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              class="border-left-0"
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :fields="fields"
      :items="provider"
      head-variant="dark"
      outlined
      show-empty
      small
      :filter="filter"
      :busy="tblBusy"
    >
      <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" variant="primary"></b-spinner>
            <strong>Loading...</strong>
          </div>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <b-button
          size="sm"
          variant="success"
          :to="{ name: 'farmer-details', params: item }"
        >
          <b-icon icon="pencil-square"></b-icon>
        </b-button>
      </template>
    </b-table>

    <b-row class="justify-content-center">
      <b-col cols="6">
        <b-pagination align="fill" size="sm" class="my-0"></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { getProfileList } from "../services/profile";
export default {
  data() {
    return {
      filter:"",
      tblBusy: false,
      fields: [
        { key: "title", label: "Outgrower name in system" },
        "Certificates",
        "GGN",
        "Expiry date",
        { key: "actions", label: "" }
      ]
    };
  },
  methods: {
    async provider() {
      this.tblBusy = true
      const profiles = await getProfileList()
      if (profiles){
        this.tblBusy = false
      }
      return profiles;
    }
  }
};
</script>
