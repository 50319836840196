import axios from "axios";

export async function getProfileList() {
  return await axios
    .get("profileList", {
      params: {
        profile: "farmwise-mobile",
        list: "Farms",
        category: "Farms",
        items:"200"
      }
    })
    .then(res => {
      // const activeFarms = res.data[0].objectList.filter((farm) => farm.isActiveMember === "true") 
      return res.data[0].objectList;
    })
    .catch(error => {
      console.log("error", error);
      return [];
    });
}

export async function getMemberList(farm_id) {
  return await axios
    .get("memberList", {
      params: {
        profile: farm_id,
      }
    })
    .then(res => {
      return res.data[0].objectList;
    })
    .catch(error => {
      console.log("error", error);
      return [];
    });
}

